/* website: 4247-lloydminsterhonda
 * created at 2023-10-18 15:00 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/honda.scss";
@import "../utils/icons.scss";

.widget-sr{
  @include clearfix;
  width: 100%;
  &.dealer__lloydminsterhonda-858{
    .listing-tile .listing-tile-image .car-info{
      position: relative;
    }
    .listing-tile .listing-tile-image{
      display:flex;
      flex-direction: column-reverse;
    }
  }
}